<style>
.c-link {
  cursor: pointer;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: bold;
  letter-spacing: .5px;
  color: #bfc8e2 !important;
  -webkit-user-select: none; /* Chrome/Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */
  -o-user-select: none;
  user-select: none;
}
.c-server-link {
  cursor: pointer;
  text-transform: uppercase;
  font-size: 0.8rem;
  font-weight: bold;
  letter-spacing: .5px;
  color: #bfc8e2 !important;
  -webkit-user-select: none; /* Chrome/Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */
  -o-user-select: none;
  user-select: none;
}
.c-link:hover {
  color: #a7b0c9 !important;
}
@media (min-width: 1366px) {
  .c-links {
    margin-left: 5rem;
    margin-top: 2rem;
  }
}
@media (max-width: 1366px) {
  .c-links {
    margin-top: 2rem;
  }
}
.c-component-col {
  margin-top: 2rem;
}
.c-link.active {
  color: white !important;
}
.mobile-menu {
  width: 100vw;
  height: 3rem;
  background-color: #545a6d;
  display: flex;
  overflow-x: scroll;
  margin-bottom: 1rem;
}
.mobile-container {
  height: 3rem;
  min-width: 4rem;

  text-align: center;

  padding-top: 0.75rem;
  padding-bottom: 2rem;

  cursor: pointer;
  margin-left: 1rem;
  margin-right: 1rem;
  color: white;
  white-space: nowrap;
}
.mobile-container-last {
  min-width: 1px !important;
}
.mobile-container.active {
  border-bottom: 3px solid var(--primary);
}
</style>
<script>
import { VclCode } from 'vue-content-loading';

import { HalfCircleSpinner, CirclesToRhombusesSpinner } from 'epic-spinners'
import {AccountStatus, Game} from '@/enums';
import CopyableText from "@/components/CopyableText";

export default {
  props: ['banlist_id'],
  components: {
    CopyableText,
    HalfCircleSpinner,
    // Desktop
    DOverview: () => import('@/components/lazy/banmanager/view/desktop/Overview'),
    DProtocol: () => import('@/components/lazy/banmanager/view/desktop/Protocol'),
    DDatabase: () => import('@/components/lazy/banmanager/view/desktop/Database'),
    DSettings: () => import('@/components/lazy/banmanager/view/desktop/Settings'),
	  DProfile: () => import('@/components/lazy/banmanager/view/desktop/Profile'),
    DIntegrations: () => import('@/components/lazy/banmanager/view/desktop/Integrations'),
    DUsers: () => import('@/components/lazy/banmanager/view/desktop/Users'),
    // Mobile
    MOverview: () => import('@/components/lazy/banmanager/view/mobile/Overview'),
    MProtocol: () => import('@/components/lazy/banmanager/view/desktop/Protocol'), // TODO: Remove avatars
    MDatabase: () => import('@/components/lazy/banmanager/view/desktop/Database'), // TODO: Rework button sizes (expand on hover/click?)
    MSettings: () => import('@/components/lazy/banmanager/view/desktop/Settings'), // Fully responsive, does not need adjustments
	  MProfile: () => import('@/components/lazy/banmanager/view/desktop/Profile'),
    MIntegrations: () => import('@/components/lazy/banmanager/view/mobile/Unavailable'), // TODO: Dedicated mobile version
    MUsers: () => import('@/components/lazy/banmanager/view/desktop/Users'),
  },
  methods: {
    changeTab(tab, options) {
      this.options = options || {};
      this.currentTab = tab;
      this.options.privileged = this.isPrivileged;
      this.options.permissions = this.permissions;
    },
    tabChangeRequest(tab) {
      this.currentTab = tab;
    },
    changeMobileTab(tab, options) {
      this.options = options || {};
      this.currentTab = tab;
      this.options.privileged = this.isPrivileged;
      this.options.permissions = this.permissions;
    },
    getData() {
      fetch(process.env.VUE_APP_ROOT_API + `v1/banmanager/${this.banlist_id}/structure`, {credentials: 'include'})
        .then(response => {
          if(response.ok){
            return response.json();
          } else {
            if(response.status === 403) this.isUnauthorized = true;
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
          }
        })
        .then(data => {
					if(data.banlist.delisted)	this.showProfile = false;
          this.permissions = data.permissions;
          this.isPrivileged = (data.permissions.administrator === true);
          this.options.privileged = (data.permissions.administrator === true);
          this.options.permissions = this.permissions;
          this.$emit('identifierLoaded', data.banlist.identifier, data.permissions.modify);
          /*
          Ready event invokes rendering of the sub-components which depend on the above data to be available.
          Sometimes, the proxy setter is too slow and we end up with a race condition, resulting in a failed page load.
          Adding a slow delay fixes this.
          */
          setTimeout(() => {
            this.ready = true;
          }, 1);
        })
        .catch(error => {
          this.handleError(error);
        });
    },
    handleError(error) {
      console.log(`[ERROR] ${error}`);
      this.error = true;
    },
    actionToggleFullpage() {
      this.toggleOverlay();
    },
    actionEnableFullpage(text, color, spinner) {
      this.overlayText = text;
      this.overlayTextColor = color;
      this.overlaySpinner = spinner;
    },
    toggleOverlay: function() {
      this.overlayEnabled = !this.overlayEnabled;
    }
  },
  mounted() {
    this.getData();
    try {
      // Should this fail for whatever reason, fall back
      let element = document.getElementsByClassName('c-mobile')[0];
      if (window.getComputedStyle(element, null).display === 'block') {
        this.is_mobile = true;
        this.changeTab('MOverview');
      }
      else this.is_mobile = false;
    } catch(e) {
      this.is_mobile = null;
    }
  },
  data() {
    return {
      is_mobile: null,
      currentTab: 'DOverview',
      options: {},
      ready: false,
      error: false,
      isUnauthorized: false,
      isPrivileged: false,
      permissions: {},
      overlayEnabled: false,
      overlayText: '',
      overlayTextColor: '',
      overlaySpinner: false,
	    showProfile: true
    }
  }
};
</script>

<template>
  <div :class="{'fullpage-overlay-enabled': overlayEnabled}">
    <div class="fullpage-overlay">
      <div class="fullpage-overlay-content">
        <h3 :class="'text-'+overlayTextColor">
          <half-circle-spinner
              v-if="overlaySpinner"
              :animation-duration="1200"
              :size="24"
              class="align-middle d-inline-block mr-2"
          />
          {{ overlayText }}
        </h3>
      </div>
    </div>
    <template v-if="error">
      <div class="row mt-4">
        <div class="col justify-content-center">
          <div  class="info-component text-center align-middle mt-auto mb-auto">
            <div class="info-component-space">
              <i class="fas fa-do-not-enter text-danger info-component-icon"></i>
            </div>
            <div class="row" style="margin-top: 20px;">
              <div class="col-lg-12" v-if="isUnauthorized">
                <h3 v-b-tooltip.hover title="Bad. Bonk." class="text-uppercase text-danger">{{$t('error.permissions.title')}}</h3>
                <h5 class="text-muted">{{$t('error.permissions.message')}}</h5>
                <h6 class="text-muted">{{$t('error.permissions.details')}}</h6>
              </div>
              <div class="col-lg-12" v-else>
                <h3 v-b-tooltip.hover title="Bad. Bonk." class="text-uppercase text-danger">{{$t('error.server.generic.title')}}</h3>
                <h5 class="text-muted">{{$t('error.server.generic.component')}}</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <!-- Desktop -->
      <div class="c-desktop">
        <div class="container-fluid" v-if="is_mobile === false">
          <div class="row">
            <div class="col-2">
              <div class="c-links">
                <div class="c-link text-muted" :class="{'active': currentTab === 'DOverview'}" v-on:click="changeTab('DOverview')">
                  Overview
                </div>
                <div v-if="permissions.list" class="c-link text-muted" :class="{'active': currentTab === 'DDatabase'}" v-on:click="changeTab('DDatabase')">
                  Database
                </div>
                <template v-if="isPrivileged">
	                <div class="c-link text-muted" :class="{'active': currentTab === 'DProfile'}" v-on:click="changeTab('DProfile')" v-if="showProfile">
		                Profile
	                </div>

                  <div class="c-link text-muted" :class="{'active': currentTab === 'DProtocol'}" v-on:click="changeTab('DProtocol')">
                    Protocol
                  </div>
                  <div class="c-link text-muted" :class="{'active': currentTab === 'DSettings'}" v-on:click="changeTab('DSettings')">
                    Settings
                  </div>

	                <!--
                  <div class="c-link text-muted" :class="{'active': currentTab === 'DVerification'}" v-on:click="changeTab('DVerification')">
                    Verification
                  </div>
                  -->
                </template>
	              <div class="c-link text-muted" :class="{'active': currentTab === 'DIntegrations'}" v-on:click="changeTab('DIntegrations')" v-if="permissions.integrations">
		              Integrations
	              </div>
                <div class="c-link text-muted" :class="{'active': currentTab === 'DUsers'}" v-on:click="changeTab('DUsers')">
                  Personnel
                </div>
              </div>
            </div>
            <div class="col c-component-col">
              <component v-if="ready" @actionEnableFullpage="actionEnableFullpage" @actionToggleFullpage="actionToggleFullpage" @tabChangeRequest="tabChangeRequest" :options="options" :banlist_id="banlist_id" v-bind:is="currentTab"></component>
            </div>
          </div>
        </div>
      </div>
      <!-- Desktop END -->

      <!-- MOBILE -->
      <div class="c-mobile">
        <div v-if="is_mobile">
          <div class="mobile-menu">
            <div class="mobile-container" :class="{'active': currentTab === 'MOverview'}" v-on:click="changeMobileTab('MOverview')">
              Overview
            </div>
            <div v-if="permissions.list" class="mobile-container" :class="{'active': currentTab === 'MDatabase'}" v-on:click="changeMobileTab('MDatabase')">
              Database
            </div>
            <template v-if="isPrivileged">
	            <div class="mobile-container" :class="{'active': currentTab === 'DProfile'}" v-on:click="changeMobileTab('DProfile')" v-if="showProfile">
		            Profile
	            </div>

              <div class="mobile-container" :class="{'active': currentTab === 'MProtocol'}" v-on:click="changeMobileTab('MProtocol')">
                Protocol
              </div>
              <div class="mobile-container" :class="{'active': currentTab === 'MSettings'}" v-on:click="changeMobileTab('MSettings')">
                Settings
              </div>
              <!--
              <div class="mobile-container" :class="{'active': currentTab === 'MVerification'}" v-on:click="changeMobileTab('MVerification')">
                Verification
              </div>
              -->
            </template>
	          <div class="mobile-container" :class="{'active': currentTab === 'DIntegrations'}" v-on:click="changeMobileTab('DIntegrations')" v-if="permissions.integrations">
		          Integrations
	          </div>
            <div class="mobile-container" :class="{'active': currentTab === 'MUsers'}" v-on:click="changeTab('MUsers')">
              Personnel
            </div>
	          <div class="mobile-container mobile-container-last"/>
          </div>
          <div class="mobile-content">
            <component @tabChangeRequest="tabChangeRequest" :options="options" :banlist_id="banlist_id" v-bind:is="currentTab"></component>
          </div>
        </div>
      </div>

      <div v-if="is_mobile === null">
        <div class="row" style="margin-top: 70px;">
          <div class="col-lg-12">
            <div class="card bg-danger">
              <div class="card-body p-2">
                <h5 class="mt-0 mb-1 text-white">
                  <i class="fas fa-ban mr-1"></i>
                  {{ $t('profile.errors.viewport.title') }}
                </h5>
                <p class="card-text text-white">
                  {{ $t('profile.errors.viewport.message') }}
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-12 justify-content-center">
            <div  class="info-component text-center align-middle mt-auto mb-auto">
              <div class="info-component-space">
                <half-circle-spinner :animation-duration="900" :size="64" class="align-middle d-inline-block info-component-icon"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
